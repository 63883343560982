/**
 * ABSTRACTS
 */
@import "Abstract/variables";
@import 'Abstract/bootstrap';

/*@import "Abstract/functions";*/
/*@import "Abstract/mixins";*/

/**
 * COMPONENTS
 */
@import 'Components/globals';
@import "Components/bootstrap";
@import "Components/scrollTop";

/*@import "@fancyapps/ui/src/Fancybox/Fancybox";*/

@import 'animate.css';


/* ==========================================================================
   Animations
   ========================================================================== */

.animate {
    animation-duration: 1s;
    animation-fill-mode: both;
    visibility: hidden;
}

.animate.visible {
    visibility: visible;
}

.animate.hidden {
    visibility: hidden;
}



@import "@fortawesome/fontawesome-pro/scss/functions";
@import "@fortawesome/fontawesome-pro/scss/variables";
@import "@fortawesome/fontawesome-pro/scss/mixins";
@import "@fortawesome/fontawesome-pro/scss/core";
/*@import "@fortawesome/fontawesome-pro/scss/larger";*/
@import "@fortawesome/fontawesome-pro/scss/fixed-width";
@import "@fortawesome/fontawesome-pro/scss/list";
@import "@fortawesome/fontawesome-pro/scss/bordered-pulled";
@import "@fortawesome/fontawesome-pro/scss/animated";
@import "@fortawesome/fontawesome-pro/scss/rotated-flipped";
@import "@fortawesome/fontawesome-pro/scss/stacked";
@import "@fortawesome/fontawesome-pro/scss/screen-reader";

@import "Fonts";

/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/font-awesome.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/flaticon.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/bootstrap.min.css";*/


@import "OverrideBootstrap5";

/*@import "Components/Bootstrap";*/


/*//@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/animate.css";*/
/*//@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/owl.carousel.css";*/
/*//@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/odometer-theme-default.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/slick.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/slick-theme.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/slicknav.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/magnific-popup.min.css";*/
/*//@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/jquery-isotope.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/metisMenu.min.css";*/
/*//@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/jquery.fancybox.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/style.min.css";*/
/*@import "../../packages/ot_sitedummy/Resources/Public/Barristar/assets/css/responsive.min.css";*/


/* Extensions*/
/*@import "../../../../packages/ot_divider/Resources/Private/Scss/OtDivider";*/
@import "Extensions/OtDivider";
/*@import "../../../../packages/ot_bt5carousel/Resources/Private/Scss/OtBt5Carousel";*/
@import "Extensions/OtBt5Carousel";
/*@import "../../../../packagesPublic/ot-pricingtable/Resources/Private/Scss/OtPricingTable";*/
@import "Extensions/OtPricingTable";

@import "../../../../packages/ot-text/Resources/Private/Scss/OtText";
@import "../../../../packages/ot_contactbox/Resources/Private/Scss/OtContactbox";

@import "PageLayout";
@import "ContentElements";




.hero .slick-next::before {
    font-family: inherit;
    content: ">";
}
.hero .slick-prev::before {
    font-family: inherit;
    content: "<";
}


a {
    color: #2f5496;
}
a:hover {
    color: #2f5496;
}
b, strong {
    color: #2f5496;
}


/* Cards*/
.card-text p {
    color: #2d2d2d !important;
}
.card-title a:hover {
    color: #c0b596;
    text-decoration: none;
}
.card-title a {
    color: #000;
    transition: all .3s;
    text-decoration: none;
}
.card-footer {
    border-top: none !important;
    background: transparent;
    color: #2d2d2d;
    padding-bottom: 2rem;
    padding-left: 2rem;
}
.card-body {
    padding: 2rem;
}
section.bg-light {
    .card {
        border: none !important;
    }
    .card-header {
        border: none;
        background-color: #fff;
    }
}
.bg-ekk-one {
    background-color: rgb(247, 247, 247);
}
.bg-ekk-two{
    background-color: rgb(225, 233, 247);
}
.bg-ekk-three {
    background-color: #fbfe95;
}
.bg-ekk-four {
    background-color: rgb(198, 206, 219);
}
.color-primary {
    color: $primary;
}

html, body {
    height: 100%;
}
.header-area {
    background-color: rgba(255, 255, 255,1.0);
}
.header-area {
    background-color: white;
    box-shadow: 0 0 4px 0 #2d2d2d;
}
#page-1 .header-area{
    background-color: #fff;
}

.main-menu ul{
    margin: 0;
}

.footer-top .h3 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 20px;
}

.header-contact a {
    color: #fff;
    text-decoration: none;
}
.header-contact a:hover{
    color: #fff;
    text-decoration: underline;
}




.breadcrumb {
    background: transparent;
}
.breadcrumb-item.active {
    color: #fff;
}
.breadcrumb a {
    color: #fff;
}

.ot-bootstrap5-new-row-inner-wrap {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Icons*/
.ot-icon-inline	svg {
    margin: 0 5px;
    line-height: inherit;
    height: 1rem;
}
.text-white.ot-icon-inline svg {
    fill: #fff;
}
div.ot-icon-inline {
    display: inline;
    width: 1em;
    height: 1em;
}

/**/

.ot-divider-fixed-bg,
.ot-divider-fixed-bg p {
    color: #fff;
}
.ot-divider-fixed-bg h1,
.ot-divider-fixed-bg h2,
.ot-divider-fixed-bg h3,
.ot-divider-fixed-bg h4,
.ot-divider-fixed-bg h5,
.ot-divider-fixed-bg h6 {
    color: #c0b596;
}


/* Rahmen bei ot_responsiveimages Bild*/
/* todo about-title löschen?!*/
.shadow-border figure {
    position: relative;
    max-width: 545px;
}
.shadow-border figure::before {
    content: "";
    background-color: #c0b596;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: -20px;
    top: -20px;
}


/**
Overlay Text
 */

.fce-ot_responsiveimages {
    position: relative;
}
.layout-overlay-text {
    position: relative;
}
.fce-ot_responsiveimages .layout-overlay-text img,
.fce-ot_responsiveimages .layout-overlay-text figure{
    margin: 0 !important;
}

.fce-ot_responsiveimages .overlay-text {
    background: rgba(21, 26, 48, 0.8);
    width: calc(100%);
    height: calc(100%);
    position: absolute;
    bottom: -30%;
    opacity: 0;
    visibility: visible;
    transition: all 0.5s;
}

.fce-ot_responsiveimages:hover .overlay-text {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}

.fce-ot_responsiveimages .overlay-text .text-inner {
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    position: absolute;
    border: 2px solid #c0b596;
    z-index: 95;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.fce-ot_responsiveimages .overlay-text .text-inner h3 {
    color: #fff;
    position: relative;
}

.fce-ot_responsiveimages .overlay-text .text-inner h3:before {
    content: "";
    position: absolute;
    left: 40%;
    bottom: -30px;
    width: 60px;
    height: 3px;
    background: #c0b596;
}

.fce-ot_responsiveimages .overlay-text .text-inner .sub {
    color: #c0b596;
}


h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    color: #282e3f;
}

.practice-area.practice-style-1{
    padding: 0;
    background-color: #f8f9fa;
}

/* Buttons Cards*/
.card .btn-primary {
    color: #fff;
    background-color: #a11531;
    border-color: #a11531;
}
.card .btn-primary:hover {
    color: #a11531;
    background-color: #dfdfdf;
    border-color: #a11531;
}
.card-title a:hover {
    color: #a11531;
}

/* Team Kreis Bild*/
.ot-contactbox-image {
    border: none;
}

.btnclassTeam p a {
    padding: 15px 30px;
    background-color: #a13033;
    color: #fff;
    border: 2px solid #fff;
    transition: all 0.5s ease;
}
.btnclassTeam p a:hover {
    color: #a13033;
    background-color: #fff;
    border: 2px solid #a13033;
    transition: all 0.5s ease;
}

/* Content Background*/
#BackgroundGreyWPE {
    background-color: #cecece;
}
#BackgroundDarkWPE {
    background-color: #2d2d2d;
}
#BackgroundGreyWPEHell {
    background-color: #f9f9f9;
}
#BackgroundGreyWPE p {
    color: #444;
}
#backgroundlightGreyWPE {
    background-color: #dfdfdf;
}

/* Header Top Anpassungen*/
.navbar {
    border-bottom: 2px solid #2f5496;
}
.header-top {
    background: #2f5496 !important;
}
#page-1 .header-area {
    background-color: #fff !important;
}
.header-top ul li {
    color: #fff;
}
.header-area .logo img {
    width: 240px !important;
}
.header-area .logo {
    margin-top: 19px;
}
.header-top .account_login-area, .header-top .header-contact {
    margin-bottom: 0 !important;
}
.main-menu nav ul li a {
    color: #00428c !important;
}

/* Footer*/
#pagefooter {
    padding: 5rem 0;
    background-color: #f7f7f7;
    color: #fff;
    border-top: 13px solid #134093;
}
#pagefooter a, #pagefooter strong, #pagefooter p {
    color: #134093;
}
.footer-area {
    background: #A13034 !important;
}
.footer-top {
    padding-top: 70px;
    padding-bottom: 30px;
}
.footer-top p {
    color: #fff;
}
.footer-area p {
    margin-bottom: 0.4rem;
}
.footer-area p a {
    color: #fff;
}
.footer-area p a:hover {
    color: #fff;
}
.footer-area span a {
    color: #fff;
}
.footer-area span a:hover {
    color: #fff;
}

/* Content Font Size*/
.ot-bootstrap5-new-row-outer-wrap p, .ot-bootstrap5-new-row-outer-wrap li span, .ot-bootstrap5-new-row-outer-wrap p span {
    font-size: 18px !important;
}

/* Content Element Anpassung*/
#ce96 ul {
    list-style-type: circle;
}
#ce96 ul li {
    margin-left: 30px;
}
.ot-divider-layout-2 ul li {
    font-size: 18px;
}



/*Silder*/
.hero .slide, .hero {
    height: 855px;
}
.slick-prev, .slick-next {
    margin-top: 60%;
}

/* List Style*/
.ListStyleCircle li {
    list-style: circle;
    margin-left: 20px;
    font-size: 18px;
    color: #666;
}

/* Card*/
.card-img-top {
    height: auto;
}
.card-body {
    background: #dfdfdf;
}
/*Ueberschriften*/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1.0rem;
}
h2, .h2 {
    color: #2f5496;
}
h1 {
    color: #2f5496;
}
.card-deck h3 {
    font-size: 1.70rem;
}
h3, h3 {
    color: #2f5496;
}

/*breadcrumb*/
.breadcumb-area {
    margin-top: 92px;
}
.breadcumb-area:before {
    content: "";
    background: linear-gradient(to right,rgba(21,26,48,0.79) 8%,rgba(21,26,48,0.79) 28%,rgba(21,26,48,0.07) 95%,rgba(21,26,48,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c9151a30',endColorstr='#00151a30',GradientType=1);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}


/*Figur Fachbereiche*/
.fachbereichh3 h3 {
    font-size: 1.3rem;
}

.hero .hero-slider .slide-caption > h2, .hero .hero-slider .slide-caption > p, .hero .hero-slider .slide-caption > .btns {
    opacity: 1 !important;
}

/*Kontakt Form Anpassungen*/
.form-navigation .btn-primary {
    color: #fff;
    background-color: #a13034;
    border-color: #a13034;
}
.form-navigation .btn-cancel {
    color: #fff;
    background-color: #a13034;
    border-color: #a13034;
    margin-right: 20px;
}

/* CardBoxenAbstaende*/
.beratercardtextabst  {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 0;
    }
    .ot-contactbox li a, .ot-contactbox li {
        margin-bottom: 0.35rem;
    }
}

/*Teaserboxen Startseite*/
.BorderPadding{
    padding-right: 17px;
    padding-left: 17px;
}
.marginBottomSM{
    margin-bottom: 30px;
}
.col-lg-3{
    width: 25%;
}
.imgBorder{
    border: 2px solid #000;
}
.imgLink{
    box-shadow: 0 0 4px 0 #a62247;
}


/* media queries*/

@media (max-width: 5200px) {
    .buttonleistestwrhlb p a {
        padding: 20px 30px;
        background: #a13034;
        color: #fff;
        border-radius: 6px;
        border:2px solid #a13034;
    }
    .buttonleistestwrhlb p a:hover {
        padding: 20px 30px;
        background: #fff;
        color: #a13034;
        border-radius: 6px;
        border:2px solid #a13034;
    }
    .buttonleistestwrhlb {
        margin-top: 16px;
    }
    .breadcumb-area {
        min-height: 854px;
    }
    .breadcumb-wrap {
        padding-top: 430px;
    }

    .imgbordersmall img {
        border: 1px solid #000;
    }

    .pabstandnull p {
        margin-bottom: 0;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption {
        padding-top: 385px;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption span {
        text-shadow: 2px 2px 0 #000;
    }
    .headtopmargsbnchtndschzgfi svg, .headtopmargsbnchtndschzg svg {
        margin-top: -4px;
    }
}

@media (max-width: 1920px) {
    .breadcumb-area {
        min-height: 645px;
    }
    .hero .slide, .hero {
        height: 641px;
    }
}
@media (max-width: 1680px) {
    .breadcumb-area {
        min-height: 624px;
    }
    .hero .slide, .hero {
        height: 561px;
    }
    .breadcumb-wrap {
        padding-top: 353px;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption {
        padding-top: 262px;
    }
    .hero-style-1 .slide-caption h2, .hero-style-2 .slide-caption h2 {
        font-size: 50px;
    }
}
@media (max-width: 1400px) {
    .hero .slide, .hero {
        height: 468px;
    }
    .breadcumb-area {
        min-height: 520px;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption {
        padding-top: 232px;
    }
    .hero-style-1 .slide-caption h2, .hero-style-2 .slide-caption h2 {
        font-size: 43px;
    }
    .breadcumb-wrap h2 {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #fff;
    }
}
@media (max-width: 1200px) {
    .hero .slide, .hero {
        height: 399px;
    }
    .breadcumb-wrap {
        padding-top: 260px;
    }
    .breadcumb-area {
        min-height: 445px;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption {
        padding-top: 150px;
    }
    .buttonleistestwrhlb p a {
        padding: 20px 20px;
        background: #a13034;
        color: #fff;
        border-radius: 6px;
        border: 2px solid
        #a13034;
    }
    .buttonleistestwrhlb p a:hover {
        padding: 20px 20px;
        background: #fff;
        color: #a13034;
        border-radius: 6px;
        border:2px solid #a13034;
    }
}
@media (max-width: 1196px) {
    .header-top li {
        display: inline-block;
        padding: 0 10px;
        color: #fff;
    }
    .header-top ul.header-contact {
        text-align: right;
        margin-top: 15px;
    }
    .headtopmargsbnchtndschzg {
        padding-bottom: 19px;
    }
    .ot-contactbox {
        p {
            margin-bottom: 0;
        }
    }
    .beratercardtextabst .ot-contactbox li a, .beratercardtextabst .ot-contactbox li {
        margin-bottom: .1rem;
    }
    .card-deck h3 {
        font-size: 1.30rem;
    }
    .parallax-container {
        display:none;
    }
}
@media (max-width: 1050px) {
    .hero .slide, .hero {
        height: 343px;
    }
    .breadcumb-area {
        min-height: 380px;
    }
    .breadcumb-wrap {
        padding-top: 225px;
    }
    .hero.hero-slider-wrapper.hero-style-2 .slide-caption {
        padding-top: 80px;
    }
    .breadcumb-wrap h2 {
        font-size: 45px;
    }
    .headtopmargsbnchtndschzg {
        padding-bottom: 19px !important;
    }
}
@media (max-width: 1200px) and (min-width: 991px) {
    .main-menu nav ul li a {
        font-size: 13px !important;
        padding: 35px 12px !important;
    }
}
@media (max-width: 991px) {
    .buttonleistestwrhlb {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .breadcumb-area {
        min-height: 285px;
    }
    .breadcumb-wrap {
        padding-top: 150px;
    }
    .breadcumb-area {
        margin-top: 87px;
    }
    .breadcumb-wrap h2 {
        font-size: 38px;
    }
    .slicknav_nav .slicknav_row, .slicknav_nav a {
        padding: 12px 10px;
        margin: 2px 5px;
    }
    .slicknav_nav {
        background: #a13034;
    }
    .slicknav_nav a:hover {
        background: #bf4044;
        color: #fff;
    }
    .fbabsttopsbncchts {
        margin-top: 60px;
    }
    .headtopmargsbnchtndschzgfi {
        margin-top: 13px !important;
        padding-bottom: 14px !important;
    }
    .headtopmargsbnchtndschzg {
        margin-top: 0 !important;
        padding-bottom: 14px !important;
    }
    .slicknav_menu .slicknav_icon-bar {
        box-shadow: 0 1px 0 rgb(0, 0, 0);
    }
    .ot-contactbox-bg-right {
        border-left: none !important;
    }
    .ot-contactbox-bg-right .ot-contactbox-title {
        display:none;
    }
    .card-deck .card  {
        min-width: 45%;
        max-width: 45%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 767px) {
    .card-deck .card {
        min-width: 100%;
        max-width: 100%;
    }
    .parallax-container {
        display: none;
    }
    .abtsectt {
        padding-top: 0;
    }
    .breadcumb-area {
        margin-top: 74px;
    }
    .hero-style-1 .slide-caption h2, .hero-style-2 .slide-caption h2 {
        font-size: 36px;
    }
    .abstbtsmartccrt {
        margin-bottom: 50px !important;
    }
    .fbabsttopsmartccr, #ce430, #ce127 {
        padding-top: 60px;
    }
    .breadcumb-wrap h2 {
        font-size: 25px;
    }
    .breadcumb-wrap {
        padding-top: 150px;
    }
    .ot-contactbox-bg-right {
        .ot-contactbox-title {
            display: none;
        }
    }
}
@media (max-width: 575px) {
    .logo {
        margin-top: 0;
        max-width: 270px;
    }
    .header-area .logo {
        margin-top: 0;
    }
}
@media (max-width: 398px) {
    .header-top ul.header-contact {
        text-align: center;
    }
}
