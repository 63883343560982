
/* Enable features */
$enable-responsive-font-sizes: true;
$enable-shadows: true;
$enable-smooth-scroll: true; // v5

// Extensions
$ot-divider-overlay-color: #000 !default;
$ot-divider-overlay-opacity: 0.5 !default;

// Social Media
$color-facebook: #1877f2;
$color-linkedin: #0073b1;
$color-twitter: #1da1f2;

// Bootstrap Variables

$primary: red;

$theme-colors: (
    "primary":    $primary
//    "secondary":  $secondary,
//    "success":    $success,
//    "info":       $info,
//    "warning":    $warning,
//    "danger":     $danger,
//    "light":      $light,
//    "dark":       $dark
);

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1378px,
);

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$ot-divider-overlay-color: #000;
$ot-divider-overlay-opacity: 0.3; // = 0.7

$site-url-prefix: '../../Assets/Website';
